.main {
	display: flex;
	flex-direction: column;
}

.main__body {
	padding: 2rem 1rem 3rem;
	flex-grow: 1;
}

.main__footer {
	position: sticky;
	bottom: 0;
}
