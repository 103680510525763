.content {
	background-color: var(--general---background-color);

	width: 100vw;
	max-width: 75rem;
	border-radius: var(--enrollment-application__content---border-radius);
	margin: 8vh auto 5rem;
}

@media (min-width: 800px) {
	.content {
		width: 80vw;
		box-shadow: var(--general---box-shadow);
	}
}
