.parent-card {
	padding: 0.5rem 1rem 0;
	border: 1px solid var(--verita-light-blue);
	border-radius: var(--general__tile---border-radius);
}

.parent-card__name {
	text-align: center;
	font-size: 1.25rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	width: 100%;
}

.parent-card__stat-grid {
	font-size: 0.9rem;
}

.parent-card__stat {
	color: #777;
	white-space: nowrap;
}

.parent-card__stat__icon {
	flex-shrink: 0;
}

.parent-card__edit-button {
	width: 100%;
}

.parent-modal {
	width: 70rem;
	max-width: 90vw;
}
