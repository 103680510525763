html {
	/* define theme CSS custom properties here */
	--header__bar---height: 13rem; /* approx */
	--simple-header__bar---height: 10.5rem; /* approx */
	--header__bar__navigation-toolbar---width: 100vw;
	--header__bar__navigation-toolbar---height: 1.75rem;
	--header__drawer---width: 90vw;
	--header__side-bar---width: 13rem;
	/* --header---height is used by other elements that depend on the header, the header's height is set internally. */
	--header---height: 0px;

	--general---theme-color: var(--verita-blue);
	--general---theme-text-color: #fff;

	--header---color: var(--general---color);
	--header---background-color: var(--general---background-color);
	--footer---color: var(--general---theme-text-color);
	--footer---background-color: var(--general---theme-color);
	--general__header---color: var(--general---theme-text-color);
	--general__header---background-color: var(--general---theme-color);

	--heading--level-1---margin: 0 0 1rem;

	--heading--level-1---font-size: 1.7rem;
	--heading--level-2---font-size: 1.5rem;
	--heading--level-3---font-size: 1.35rem;
	--heading--level-4---font-size: 1.2rem;

	--general__tile---border-radius: 0.75rem;

	--enrollment-application__content---border-radius: 3rem;

	font-family: sans-serif;

	scroll-behavior: smooth;
}

body {
	--table__header---top: 0px;
	--table__scroll-target---scroll-margin-top: 0px;

	color: var(--general---color);
	background: linear-gradient(30deg, var(--verita-green), var(--verita-blue) 40%, var(--verita-dark-blue));

	min-height: 100vh;
}

body.body--with-header {
	--header---height: var(--header__bar---height);
	--pop-up---top: 1rem;

	padding-top: 0;
}

body.body--with-simple-header {
	--header---height: var(--simple-header__bar---height);
}

body.body--with-header.body--with-navigation-toolbar {
	--header---height: calc(var(--header__bar---height) + var(--header__bar__navigation-toolbar---height));
}

body.body--with-header-side-bar {
	--pop-up---right: calc(var(--header__side-bar---width) + 1rem);
	--header__bar__navigation-toolbar---width: calc(100vw - var(--header__side-bar---width));

	padding-right: var(--header__side-bar---width);
}

@media print {
	body,
	body.body--with-header,
	body.body--with-header.body--with-navigation-toolbar {
		--header---height: 0px;
	}
}

main {
	width: 100%;
	min-height: 100vh;
}

body.body--with-header main {
	min-height: calc(100vh - var(--header---height));
}

.scroll-target {
	scroll-margin-top: 0px;
	scroll-margin-bottom: var(--floating-elements--bottom---height, 0px);
}

.print-only {
	display: none;
}

@media print {
	.no-print {
		display: none;
	}

	.print-only {
		display: initial;
	}
}

.main-content-section {
	width: 100%;
	max-width: 50rem;
}

@media (max-width: 500px) {
	html {
		--general__field---width: 100%;
	}
}

.footer--hidden {
	display: none;
}
