.student-card {
	padding: 0.5rem 1rem 0;
	border: 1px solid var(--verita-light-blue);
	border-radius: var(--general__tile---border-radius);
}

.student-card__name {
	text-align: center;
	font-size: 1.25rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

.student-card__stat {
	color: #777;
}

.student-card__edit-button {
	width: 100%;
}

.student-modal {
	width: 70rem;
	max-width: 90vw;
}
