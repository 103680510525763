.header {
	position: static;
}

.header__bar {
	--internal-link---color: var(--header---color);
	--nav-link---color: var(--header---color);
	color: var(--header---color);

	--nav-link---text-decoration: none;
	--nav-link--hover-focus---text-decoration: none;
	--nav-link--active---text-decoration: none;

	width: 100%;
	padding: 1rem 1rem 0.5rem;

	position: relative;
}

.nav-link {
	--nav-link--active---color: var(--verita-blue);
	--nav-link--location-active---color: var(--verita-blue);
}

.nav-link--location-active {
	color: var(--verita-blue);
}

.nav-link__bar {
	width: 5rem;
	height: 3px;
	background-color: #ddd;
}

.nav-link__text {
	display: none;
}

@media (min-width: 400px) {
	.nav-link__bar {
		width: 7rem;
	}

	.nav-link__text {
		display: initial;
		font-size: 0.9rem;
	}
}

@media (min-width: 800px) {
	.nav-link__bar {
		width: 10rem;
	}

	.nav-link__text {
		font-size: 1rem;
	}
}

.nav-link--location-active .nav-link__bar {
	background-color: var(--verita-blue);
}

.logo-container {
	width: 12rem;
	height: fit-content;
}

.logo-container img {
	width: 100%;
	height: auto;
}
